import './App.css';
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom'

import TyC from './pages/TyC';

function App() {
  return (
    <Router>
      <nav>
        <Link to="/"> Terminos y condiciones </Link>
      </nav>
      <Routes>
        <Route path="/" element={<TyC/>}></Route>
      </Routes>
    </Router>
  );
}

export default App;
