import React from "react";

function TyC() {
    return <div className="homePage"> 
        <div className="post">
        <div className="postHeader">Términos y Condiciones
ACTUALIZADO EL 10 DE ENERO DE 2023</div>
        <div className="postTextContainer">
        
  
Esta aplicación Móvil o Web en lo sucesivo se denominará como “GRUAPP. El Usuario y/o proveedor que acceda navegue o utilice GRUAPP en lo sucesivo se denominará como el “Usuario y/o Proveedor” Aceptando con su uso irrevocablemente los actuales Términos y Condiciones - Politica de Privacidad y Tratamiento de Datos.  que GRUAPP y sus compañías afiliadas prestan el servicio de tecnología que habilita a los Usuarios  y Conductores para acceder a una plataforma de tecnología que les permite, con base en las necesidades de desplazamiento de los Usuarios y/o proveedor, organizar y planear los servicios ofrecidos, en línea con Personas Naturales, Taxistas  y terceros independientes proveedores de tales servicios, incluyendo terceros de alquiler de vehiculos independientes y proveedores independientes (los “Servicios”).

Los terceros proveedores del Servicio (los “Proveedores de Servicios”), interactuarán con GRUAPP a través de la aplicación GRUAPP. El Proveedor que se conecte con el Usuario a través de GRUAPP se denominará en lo sucesivo como el “Proveedor Designado”. El Usuario reconoce que la Compañía NO presta servicios de transporte y que NO opera como una compañía de transportes, siendo dichos Servicios prestados directamente por el Proveedor de servicio Designado, quien es independiente y no actúa como empleado de la Compañía o de cualquiera de sus compañías afiliadas. Esta aplicación utiliza el acceso a la ubicación siempre o cuando tu decidas en segundo plano para mejorar los puntos de inicio y fín, la atención al cliente y el propósito de seguridad. Esta función permite durante el viaje garantizar el correcto cobro de la aplicación y el seguimiento de seguridad del mismo.

 El Usuario y/o proveedor designado se sujetará a los presentes Términos y Condiciones de Uso de GRUAPP  (en lo sucesivo denominados como los “Términos y Condiciones”). Antes de utilizar GRUAPP, por favor lea los presentes Términos y Condiciones con atención (especialmente las partes resaltadas en negrilla). Tenga en cuenta que el Usuario y/o Proveedor Designado no podrá usar, ni tener pleno acceso a los Servicios, hasta que no haya proporcionado todos los documentos e información que se requieren, aprobados por GRUAPP, y se obligue a sujetarse a los presentes Términos y Condiciones.
Si el Usuario y/o Proveedor Designado continúa accediendo y usando los Servicios, se considerará que el Usuario ha leído y aceptado todos y cada uno de los términos establecidos en este documento y acordado que los presentes Términos y Condiciones son legalmente vinculantes para el Usuario y la Compañía. El Usuario y/o Proveedor Designado observará los presentes Términos y Condiciones de buena fe al utilizar GRUAPP.
Los presentes Términos y Condiciones son aplicables para todos los Usuarios o personas que descarguen la aplicación de GRUAPP, y están sujetos a modificación en cualquier momento como resultado de ajustes a la política comercial de GRUAPP.
El Usuario debe visitar frecuentemente esta página web y/o la aplicación GRUAPP para mantenerse al tanto de los términos vigentes. Las notificaciones, términos u otros requerimientos expresamente especificados o integrados en la página web y/o en GRUAPP, formarán parte integral de los presentes Términos y Condiciones. Ciertas partes de los Términos y Condiciones podrán ser sustituidos por avisos legales, términos o requerimientos más actualizados expresamente notificados, especificados o integrados en la página web y/o en GRUAPP. Los avisos, términos o requisitos actualizados comenzarán a regir a partir de la publicación o notificación de dichas actualizaciones por parte de la Compañía. Al aceptar estos Términos y Condiciones, se entenderá que el Usuario, mediante el acceso y uso de los Servicios, también ha leído con atención y aceptado, dichos términos actualizados, sustituidos, referidos o notificados. 
Si el Usuario sigue utilizando los servicios de la Compañía, se considerará que el Usuario ha aceptado los Términos y Condiciones actualizados.
Los presentes Términos y Condiciones expresamente dejan sin efecto cualquier acuerdo o arreglo previo que la Compañía haya celebrado con el Usuario para efectos de utilizar GRUAPP y los Servicios.
GRUAPP se reserva el derecho de buscar soluciones, incluyendo dar por terminados los presentes Términos y Condiciones o cualquier Servicio con respecto al Usuario y/o proveedor Designado, o en general, dejar de ofrecer o negar el acceso a los Servicios o a cualquier parte de estos, si GRUAPP considera que el Usuario ha incurrido en cualquier incumplimiento.
Así mismo, GRUAPP se reserva el derecho de negarse a prestar los Servicios al Usuario o negar al Usuario el uso de Páginas Web, servicios o aplicaciones de GRUAPP sin causa alguna si el Usuario no está de acuerdo con estos Términos y Condiciones.
1. Registro del Usuario
1.1 Para utilizar el Servicio, el Usuario y/o Proveedor Designado debe descargar GRUAPP, instalarla en su dispositivo móvil y completar exitosamente los procesos de registro. Al registrarse, el Usuario deberá asegurarse que toda la información que haya proporcionado sea precisa, completa y válida.
1.2 El Usuario reconoce y acuerda que una vez que el Usuario sea un usuario registrado de GRUAPP, la cuenta de servicio del Usuario en la Compañía se abrirá de forma predeterminada. La cuenta y contraseña predeterminadas deberán ser la cuenta y contraseña de la cuenta de GRUAPP del Usuario.
1.3 El Usuario y cualquier persona que use los Servicios deberá tener mínimo 18 años de edad o la mayoría de edad fijada en la jurisdicción del Usuario (en caso de ser mayor a 18 años) o tener plena capacidad para ejecutar un contrato de conformidad con la regulación aplicable. En caso de que cualquier persona autorizada por el Usuario para recibir los Servicios tecnológicos proporcionados por los Proveedores de Servicio no tenga plena capacidad para ejecutar un contrato o sea una persona con capacidad de ejercicio limitada para ejecutar contratos, dicha persona deberá estar acompañada por el Usuario, y el Usuario asumirá cualquier responsabilidad y consecuencia derivada de o relacionada con los presentes Términos y Condiciones. El Usuario no cederá o transferirá de ninguna forma la cuenta vinculada a su nombre, creada para el uso de los servicios de la Compañía, a ninguna persona natural o jurídica.
2. Servicios
2.1 Los Servicios se prestan al Usuario a través de GRUAPP. El Usuario podrá elegir y utilizar los Servicios a través de GRUAPP, descargada e instalada en el dispositivo móvil del Usuario. Los Servicios permiten al Usuario tramitar y solicitar Servicios tecnológicos prestados por el Proveedor Designado, quien no es un empleado, funcionario ni representante de la Compañía.
2.2 El Usuario reconoce que la Compañía no provee servicios de transporte, logística o mensajería y que la capacidad del Usuario para obtener servicios de movilidad y/o logística a través del uso de los Servicios no constituye a la Compañía como un proveedor de servicios de transporte, logística o mensajería ni como un operador de transporte.
2.3 A menos que la Compañía acuerde lo contrario en un contrato por escrito adicional con el Usuario, los Servicios están disponibles únicamente para uso personal y no comercial del Usuario.
2.4 Para utilizar los Servicios, el Usuario deberá proporcionar a la Compañía cierta información personal. El Usuario se obliga a mantener la información personal que requiera GRUAPP en forma precisa, completa y actualizada. En caso de no
poder mantener dicha información de acuerdo con lo descrito, el acceso y el uso de los Servicios podría verse afectado.
2.5 Servicios Intermunicipales: El valor que nuestra GRUAPP refleja al momento de la solicitud, es un estimado del valor del trabajo de nuestro proveedor de servicio desde el punto de Origen al punto de Destino, por lo anterior al momento de Terminar el servicio este valor definitivo, debe ser pago X 2 y Adicionalmente los peajes X 2, dado que el proveedor de servicio te dejará en tu lugar de destino y deberá devolverse a la ciudad de origen, lo cual hace que los gastos de combustible, tiempo y peajes deban ser contemplados en el valor a cobrar por parte de nuestro proveedor de servicio.
3. Uso de los Servicios
3.1 GRUAPP hará esfuerzos razonables para prestar los servicios al Usuario sujeto a la disponibilidad de los Proveedores de Servicio en el momento y lugar en que el Usuario haga la solicitud.
3.2 GRUAPP no será responsable por pérdida o daño alguno cuando un Usuario solicite a un Proveedor Designado enviar únicamente artículos, sin que el Usuario viaje en el vehículo.
3.3 El Usuario se compromete a no viajar con artículos peligrosos, que estén prohibidos o sean considerados inapropiados por alguna ley, norma o reglamento aplicable; de lo contrario, el Proveedor Designado tendrá derecho a rehusarse a prestar el Servicio y el Usuario asumirá cualquier consecuencia y responsabilidad que resulte de transportar dichos artículos.
3.4 El Usuario no se comportará de manera incivilizada, ni contraria a la moral o el orden público (incluyendo fumar, beber alcohol, u otras conductas) al utilizar los Servicios y durante el servicio llevado a cabo por el Proveedor Designado. 
3.5 El Usuario no pedirá a los Proveedores de Servicio Designados, ni hará que éstos lleven a cabo, acto alguno que viole cualquier reglamento de tránsito u otra ley o reglamento aplicable. En el caso de una sanción administrativa, lesión, accidente de tráfico, daños al vehículo u otras pérdidas derivadas de tales actos prohibidos por parte del Usuario, el Usuario indemnizará a la parte que haya sufrido la(s) pérdida(s).
3.6 El Usuario no tratará, publicará ni revelará información personal de cualquier otro Usuario o Proveedor Designado a que haya accedido al utilizar los Servicios, a persona alguna y para ningún propósito, salvo para efectos del Servicio.
3.7 El Usuario será responsable de todas las actividades que ocurran en la cuenta de servicio del Usuario, y se obliga a mantener la seguridad y confidencialidad del nombre de Usuario y contraseña de la cuenta del Usuario en todo momento. Salvo que la Compañía permita lo contrario por escrito, el Usuario únicamente podrá tener una cuenta de servicio.
3.8 Al utilizar los Servicios, el Usuario acepta que la Compañía podrá contactarlo(a) por cualquier medio de contacto que el Usuario haya otorgado, u otorgado en nombre de cualquier otra persona, en relación con la cuenta de servicio del Usuario, incluso para propósitos comerciales.
3.9 El Usuario utilizará los Servicios únicamente para uso personal y no podrá acceder a los mismos para obtener inteligencia comercial o de negocio en beneficio propio o de terceros.
3.10 La recopilación y uso de información personal en relación con los Servicios se describe en la Política de Privacidad de GRUAPP, la cual hace parte integral de estos Términos y Condiciones.
3.11 El Usuario acepta y reconoce que no existe una relación de subordinación o laboral entre el Proveedor Designado, o cualquier persona o entidad que administre las actividades del Proveedor Designado y GRUAPP o cualquiera de sus afiliadas, derivadas de cualquiera de las actividades realizadas por el Proveedor Designado, a los que se aplican estos Términos y Condiciones y en cualquiera de las leyes y regulaciones laborales, de seguridad social y fiscales de Colombia.
3.12 El Usuario reconoce que cualquier individuo o entidad que administre o esté relacionado con las actividades realizadas por el Proveedor Designado no está empleado, ni bajo relación de subordinación, ni está bajo el control de GRUAPP.
4. Obligaciones y Garantías
4.1 El Usuario garantiza que la información proporcionada a GRUAPP será cierta, precisa y completa. En relación con los Servicios, GRUAPP se reserva el derecho de verificar la información del Usuario.
4.2 Al utilizar los Servicios o GRUAPP, el Usuario también se obliga a lo siguiente:
a. El Usuario utilizará los Servicios o descargará GRUAPP para uso personal y no revenderá los mismos a tercero alguno;
b. el Usuario no utilizará los Servicios ni GRUAPP para fin ilegítimo alguno, incluyendo sin limitación, transmitir o almacenar materiales ilegales o para fines fraudulentos;
c. el Usuario no utilizará los Servicios ni GRUAPP para acosar u obstaculizar a otros ni para causarles inconvenientes.
d. el Usuario no afectará la operación normal de la red;
e. el Usuario no intentará dañar los Servicios ni a GRUAPP;
f. el Usuario proporcionará la documentación de identificación que solicite GRUAPP razonablemente;
g. el Usuario efectuará todos los pagos por los Servicios solicitados; y
h. el Usuario cumplirá con todas las leyes aplicables del país/región, provincia y/o ciudad en donde utilice GRUAPP o los Servicios.
4.3 Es obligación del Usuario mantener todo el hardware o software del dispositivo móvil actualizado para que soporte los requerimientos vigentes de GRUAPP. La Compañía no será responsable de problema alguno que pueda surgir cuando el Usuario no utilice la versión más reciente y actualizada de GRUAPP, y/o utilice GRUAPP en cualquier dispositivo móvil que no cumpla con los requerimientos relevantes vigentes de GRUAPP. El Usuario acepta que la Compañía podrá actualizar los requerimientos de software y hardware del teléfono móvil de GRUAPP de tiempo en tiempo.
5. Pago
5.1 El uso de los Servicios derivará en cargos al Usuario correspondientes al precio de los servicios de movilidad que reciba del Proveedor Designado. Los precios por dichos servicios de movilidad prestados por el Proveedor Designado serán los establecidos por el respectivo gobierno local de acuerdo con las normas de la regulación aplicable para el servicio serán calculados por el APP directamente bajo los parámetros de Tiempo y Distancia.
5.2 Después de que el Usuario haya recibido los servicios de movilidad solicitados a través del uso de los Servicios, GRUAPP facilitará su pago como agente de cobro en nombre del Proveedor Designado. El pago efectuado por el Usuario por dichos Servicios se considerará como el pago efectuado directamente al Proveedor Designado por los servicios de movilidad. Por lo tanto, GRUAPP únicamente actuará como agente de recolección en nombre del Proveedor Designado para los casos que aplique distintos a Efectivo.
5.3 El Usuario pagará inmediatamente el cargo total de la orden presentada por GRUAPP. Si hay cualquier cargo vencido, la Compañía tendrá derecho a negarse a prestar Servicios al Usuario.
5.4 El Usuario podrá pagar en GRUAPP a través de su cuenta de pago electrónico y sistemas de pago bancario en línea. El procesamiento de los pagos estará sujeto a los presentes Términos y Condiciones y a los términos y políticas de los proveedores de servicios de pago electrónico y bancos emisores de las tarjetas de crédito/débito. GRUAPP no será responsable de ningún error cometido por los proveedores de servicios de pago electrónico o por los bancos. GRUAPP obtendrá los detalles de las operaciones específicas relacionadas con el uso de los Servicios por el Usuario. GRUAPP cumplirá estrictamente con las leyes y reglamentos aplicables y las políticas de la Compañía al utilizar dicha información.
5.5 Una vez que el Usuario haya efectuado el pago correspondiente, GRUAPP podrá emitir un resumen de las tarifas cobradas al Usuario, como un comprobante de pago, el cual no será considerado como una factura para efectos fiscales.
5.6 El Usuario podrá gestionar sus viajes. El Usuario podrá verificar el estado de su orden en GRUAPP.
5.7 El Usuario verificará el monto que efectivamente pagará por la orden inmediatamente después de completar cada orden. Si el Usuario tiene cualquier objeción en cuanto al monto del pago, contactará a GRUAPP inmediatamente.
5.8 Los servicios de telecomunicaciones y los cobros relacionados con estos, en los que se incurra por el uso de GRUAPP, son prestados por los operadores de  telecomunicaciones y no por GRUAPP.
6. Indemnización
6.1 Al utilizar los Servicios de GRUAPP, el Usuario se obliga a indemnizar a GRUAPP por todas y cada una de las reclamaciones, costos, compensación, pérdidas, deudas y gastos, incluyendo sin limitación, honorarios de abogados y gastos y costas judiciales, derivadas de o relacionadas con los siguientes eventos:
a. Usuario incumpla o viole cualquier término de los presentes Términos y Condiciones o cualquier ley o reglamento aplicable (ya sea o no que se mencione en este instrumento);
b. el Usuario infrinja cualquier derecho de cualquier tercero;
c. el Usuario abuse de GRUAPP o los Servicios;
d. el Usuario cause daños al vehículo o su equipo interno, o al Proveedor Designado debido a negligencia grave o dolo.
(en lo sucesivo denominados como un “Acto Indebido”).
7. Restricciones Regulatorias Locales
GRUAPP no incide o dirige a persona alguna para acceder a GRUAPP en cualquier jurisdicción en la que (debido a la nacionalidad, residencia u otro motivo) el acceso a o disponibilidad de GRUAPP esté prohibido o esté sujeto a cualquier restricción, incluyendo requerimientos de registro u otros requerimientos dentro de dicha jurisdicción. GRUAPP se reserva el derecho de limitar el acceso a GRUAPP a cualquiera de estas personas. Cualquier persona que acceda a GRUAPP en cualquier jurisdicción lo hace por iniciativa propia y será responsable de cumplir con las leyes y reglamentos locales aplicables. GRUAPP no será responsable frente a ningún Usuario y/o proveedor Designado por pérdida o daño alguno, ya sea conforme una disposición contractual, por responsabilidad civil (incluyendo negligencia), incumplimiento de un deber conforme a la ley o por otro motivo, aun cuando sea previsible, derivado de o relacionado con el uso por una persona en una jurisdicción donde el acceso a o la disponibilidad de GRUAPP esté prohibido o sujeto cualquier restricción. En caso de duda, el Usuario deberá obtener asesoría legal independiente.
8. Ausencia de Garantías
8.1 GRUAPP se ofrece “en el estado en que se encuentra”. Ninguna garantía es dada ni expresa ni implícita, con respecto a GRUAPP y en el material publicado en GRUAPP, incluyendo sin limitación, a su contenido.
8.2 Salvo por lo expresamente establecido en los presentes Términos y Condiciones, todas las garantías, condiciones y declaraciones, expresas o implícitas por ley o de otra manera establecidas por GRUAPP (incluyendo, sin limitación, garantías en cuanto a calidad satisfactoria, idoneidad para un fin o pericia y cuidado) quedan excluidas por esta disposición cuando la ley lo permita.
9. Limitación de la Responsabilidad de GRUAPP
9.1 La información proporcionada y los Servicios recomendados al Usuario en los sitios web de GRUAPP son únicamente para referencia del Usuario. GRUAPP hará esfuerzos razonables para garantizar la precisión de dicha información, en el entendido de que GRUAPP no garantiza que dicha información esté libre de cualquier error, defecto, software malicioso o virus. 
9.2 GRUAPP no será responsable por daño alguno que resulte del uso de los (o cualquier imposibilidad de usar el) sitios web de GRUAPP. Asimismo, GRUAPP no será responsable por daño alguno que resulte del uso de (o cualquier imposibilidad de usar) las herramientas de comunicación electrónica de los sitios web de GRUAPP, incluyendo, sin limitación, daños causados por fallas de transmisión, mal funcionamiento de Internet o retraso en las comunicaciones electrónicas, intercepción o manipulación de comunicaciones electrónicas por un tercero o programas de cómputo utilizados para la comunicación electrónica y transmisión de virus, falta de energía, huelgas u otras disputas laborales, disturbios, insurrecciones, revueltas; terremotos, incendios, inundación, tormentas, explosiones, guerras; actos del gobierno, órdenes de autoridades judiciales o administrativas o cualquier otra causa de fuerza mayor u omisión de terceros.
9.3 GRUAPP no será responsable por cualquier daño, indirecto, emergente, especial, ejemplar, punitivo, moral o incidental, incluyendo pérdida de utilidades, pérdida de información, o daños materiales relacionados con o que de otra manera resulten de cualquier uso de los Servicios, independientemente de la negligencia (sea activa, afirmativa, única o concurrente) de GRUAPP, aún si GRUAPP ha sido advertida de la posibilidad de tales daños.
9.4 GRUAPP no asume responsabilidad alguna con respecto a la precisión, integridad suficiencia y confiabilidad de la información y contenido incluido en GRUAPP o en los sitios web de GRUAPP, incluyendo sin limitación textos, imágenes, datos, opiniones, páginas web o enlaces, a pesar de sus esfuerzos de proporcionar información precisa y exhaustiva en la medida posible. GRUAPP se rechaza cualquier responsabilidad por cualquier error u omisión y no otorga garantía expresa o implícita alguna.
9.5 El Usuario entiende y reconoce que cuando solicita Servicios en GRUAPP, la Compañía procesa una gran cantidad de información en el servidor interno con base en dicha solicitud y proporciona información sobre Proveedores de Servicio disponibles al dispositivo móvil del Usuario, después de lo cual el Proveedor exitosamente vinculado provee servicios de movilidad fuera de línea al Usuario.
9.6 En ningún caso, la responsabilidad total de la Compañía respecto del Usuario, en relación con los Servicios, por todos los daños, pérdidas y acciones, superará el equivalente a un (01) SMLMV salvo que sea requerido por ley.
10. Autorización y Licencia
10.1 Sujeto al cumplimiento por parte del Usuario con los presentes Términos y Condiciones, GRUAPP otorga al Usuario y/o proveedor una licencia limitada, no exclusiva y no transferible para descargar e instalar una copia de las aplicaciones en un solo dispositivo móvil que el Usuario posea o controle para utilizar dicha copia de las aplicaciones únicamente para el uso personal del propio Usuario y/o proveedor.
10.2 El Usuario y/o Proveedor Designado no podrá: 
(1) otorgar licencias o sub-licencias, vender, revender, transmitir, ceder, distribuir o de otra manera explotar comercialmente o poner a disposición de terceros los servicios o aplicaciones en forma alguna; 
(2) modificar o crear obras derivadas basadas en los Servicios o las aplicaciones; 
(3) crear “enlaces” de Internet a los Servicios, o “enmarcar” o “reproducir” cualquier aplicación en cualquier otro servidor o dispositivo inalámbrico o basado en el Internet; 
(4) llevara cabo ingeniería inversa o acceder a las aplicaciones con el fin de diseñar o crear un producto o Servicio competitivo, diseñar o crear un producto utilizando ideas o gráficos similares a los Servicios o las aplicaciones, o copiar cualesquier ideas, características, funciones o gráficos de los Servicios o las aplicaciones; o 
(5) lanzar un programa o script automatizado, o cualquier programa que pueda hacer múltiples solicitudes a servidores por segundo, o que dificulte u obstaculice indebidamente la operación y/o desempeño de los Servicios o las aplicaciones.
10.3 Asimismo, el Usuario no deberá: 
(1) enviar spam o mensajes duplicados o no solicitados en violación de las leyes aplicables; 
(2) enviar o almacenar material violatorio, obsceno, amenazante, difamatorio o que de otra manera sea ilegal o ilícito, incluyendo material que infrinja los derechos de privacidad de terceros; 
(3) enviar o almacenar material que contenga virus de software, gusanos, caballos troyanos u otro código informático, archivos, scripts, agentes o programas perjudiciales;
(4) interferir con o afectar la integridad o el funcionamiento de los sitios web de GRUAPP, sus aplicaciones o los Servicios o los datos contenidos en los mismo; ni 
(5) intentar obtener acceso no autorizado a los sitios web de GRUAPP, sus aplicaciones, los Servicios o sistemas o redes relacionados.
10.4 GRUAPP tendrá derecho a investigar y ejercer todas las acciones y derechos que la legislación y los presentes Términos y Condiciones le otorgan, en el evento de cualquier incumplimiento a las obligaciones antes mencionadas. GRUAPP podrá participar en y asistir a las autoridades encargadas del orden público en las demandas en contra de cualquier Usuario que haya incumplido los presentes Términos y Condiciones. Si GRUAPP determina que cualquier contenido infringe los presentes Términos y Condiciones o de otra manera perjudica a los sitios web de GRUAPP y/o los Servicios o aplicaciones relacionados, GRUAPP se reserva el derecho de eliminar o prohibir el acceso a dicho contenido en cualquier momento sin previa notificación.
11. Política de Propiedad Intelectual
11.1 GRUAPP y sus compañías afiliadas son titulares de los derechos de propiedad intelectual de todo el contenido distribuido en GRUAPP, incluyendo, sin limitación, el software proporcionado y los productos o servicios relacionados, y tales derechos de propiedad intelectual están protegidos por las leyes. La falta de una declaración de titularidad en cierto contenido no implica que GRUAPP no tenga la titularidad de este o no pueda hacer valer sus derechos sobre el mismo y el Usuario respetará los legítimos derechos e intereses del titular y utilizará dicho contenido legalmente de conformidad con las leyes y reglamentos aplicables y el principio de buena fe.
11.2 Sin el consentimiento por escrito de GRUAPP, ninguna persona natural o jurídica utilizará, copiará, modificará, hará extractos de, incluirá con otros productos para su uso o venta, vinculará ni transmitirá vía un híper-vínculo, almacenará en un sistema de recuperación de información ni utilizará para ningún otro fin comercial cualquier parte del software, de los productos o servicios, de la información o las palabras antes mencionadas en forma alguna bajo ninguna circunstancia, excepto para la descarga o impresión para uso personal no comercial, en el entendido de que no se realizará modificación alguna a lo anterior, y que la declaración de derechos de autor u otra titularidad contenida en los mismos se conservará.
11.3 Las marcas registradas y logotipos (en lo sucesivo conjuntamente denominados como “Marcas Comerciales”) utilizadas y mostradas en dicho software constituirán las Marcas Comerciales, registradas o no registradas, de GRUAPP y sus compañías afiliadas, protegidas por las leyes. Ninguna persona utilizará contenido alguno de dicho software, “GRUAPP”, y nombres similares, así como las Marcas Comerciales en forma alguna sin el consentimiento por escrito de GRUAPP.
11.4 Si el Usuario imprime, copia, descarga, modifica o vincula cualquier parte del Contenido disponible a través de las páginas web o las aplicaciones, en incumplimiento de los presentes Términos y Condiciones, el derecho del Usuario de utilizar los sitios web de la Compañía y la aplicaciones podrán suspenderse inmediatamente y el Usuario deberá, a discreción de la Compañía, devolver o destruir cualesquier copias (electrónicas o en otro formato) de los materiales que haya realizado.
12. Servicios y Enlaces de Terceros
12.1 Durante el uso de las páginas web y aplicaciones relevantes, GRUAPP podrá, de tiempo en tiempo, proporcionar al Usuario enlaces a sitios web propiedad de y controlados por terceros, para facilitar la comunicación del Usuario con, la compra de productos o servicios o para participar en actividades promocionales ofertadas por dichos terceros. Al hacer clic en dichos enlaces, el Usuario abandonará el sitio web de GRUAPP y visitará sitios web alojados por dichos terceros que están fuera del control de GRUAPP, y donde dichos terceros han elaborado sus propios términos, condiciones, políticas de
privacidad, los cuales podrán aplicar al uso que haga el Usuario de servicios de terceros. Por lo tanto, GRUAPP no respalda el contenido y las actividades de dichas páginas web y GRUAPP no asumirá obligación o responsabilidad alguna al respecto. El Usuario entenderá plenamente el contenido y las actividades de dichos sitios web y asumirá plenamente la responsabilidad legal y los riesgos derivados de la navegación o acceso a dichos sitios web por parte del Usuario.
13. Término
13.1 Los presentes Términos y Condiciones entre el Usuario y GRUAPP tendrán un término de vigencia indefinido. 
13.2 En los casos en los que la Compañía determine o considere que el Usuario ha cometido un Acto Indebido, la Compañía se reserva el derecho de tomar las medidas que considere convenientes inmediatamente, incluyendo sin limitación, dar por terminados los presentes Términos y Condiciones con el Usuario y dejar de prestar Servicios al Usuario, según requiera el caso. Un Acto Indebido podrá resultar en las siguientes medidas:
a. La Compañía tendrá derecho a requerir la reparación o indemnización a la parte responsable respecto de cualquier cantidad que supere el monto de la indemnización que corresponda conforme a la ley, así como a tomar medidas en contra de las partes incumplidas o infractoras.
b. El Usuario tendrá derecho a dar por terminados los Servicios en cualquier momento cancelando la cuenta de Usuario del Usuario en cualquier momento de conformidad con las instrucciones publicadas en los sitios web de GRUAPP. Después de dicha cancelación, el Usuario no podrá utilizar GRUAPP ni los Servicios correspondientes hasta que el Usuario se registre nuevamente y vuelva a instalar GRUAPP exitosamente.
13.3 Aunque GRUAPP de por terminados los presentes Términos y Condiciones, el Usuario cumplirá con sus obligaciones de pago y será responsable por cualquier daño o perjuicio que pueda derivar de dicho incumplimiento.
13.4 Salvo que las leyes aplicables lo exijan o se establezca en estos Términos y Condiciones, GRUAPP no estará obligada a notificar la terminación de los presentes Términos y Condiciones con anticipación. Después de la terminación, GRUAPP enviará la notificación respectiva de conformidad con los presentes Términos y Condiciones.
14. Fuerza Mayor
En caso de que se presente una fuerza mayor, la parte afectada podrá suspender temporalmente el cumplimiento de sus obligaciones conforme al presente instrumento hasta que el efecto de dicha causa de fuerza mayor cese, y no incurrirá en incumplimiento de contrato; en el entendido, no obstante, que dicha parte hará su mejor esfuerzo para resolver dicha causa y mitigar las pérdidas. Fuerza mayor significa cualquier causa impredecible e inevitable (aun cuando sea previsible) fuera del control de las partes que impida, afecte o demore el cumplimiento por una parte de todas o cualquiera de sus obligaciones conforme al presente instrumento. Dichas causas incluyen, sin limitación, terremotos, guerra, modificación del gobierno, de las leyes reglamentos y políticas gubernamentales, virus de cómputo, ataques de hackers o suspensión de servicios prestados por empresas de telecomunicación.
15. Disposiciones Generales
15.1 Si cualquier tribunal o autoridad competente considera que cualquier disposición de los Términos y Condiciones (o parte de cualquier disposición) es inválida, ilegal o inejecutable, dicha disposición o disposición parcial se considerará eliminada, en la medida necesaria, y la validez y la exigibilidad de las otras disposiciones de estos Términos y Condiciones no se verán afectadas. Los Términos y Condiciones constituyen el acuerdo completo entre las partes en relación con su objeto y sustituyen y extinguen todos los borradores, acuerdos, arreglos y entendimientos anteriores entre las mismas, ya sean escritos u orales, relacionados con su objeto. En estos Términos y condiciones, las palabras “incluyendo” e “incluir” significan “incluyendo, pero no limitado a”.
15.2 GRUAPP podrá entregar una notificación publicando una notificación general en el sitio web de GRUAPP o enviando un correo electrónico o mensaje de texto a la dirección de correo electrónico o número de teléfono móvil registrado en la información de la cuenta del Usuario. Las notificaciones, que podrán publicarse de tiempo en tiempo, constituirán parte de los presentes Términos y Condiciones.
15.3 El Usuario no cederá ninguno de los derechos conforme a los presentes Términos y Condiciones sin el previo consentimiento por escrito de GRUAPP.
16. Otros Términos Aplicables
Los presentes Términos y Condiciones hacen referencia a los siguientes términos adicionales, los cuales también serán aplicables al uso de los sitios web, contenido, productos, Servicios y aplicaciones de la Compañía por el Usuario, mismos que, al utilizarlos, el Usuario se obliga a cumplir:
i. Política de Privacidad de GRUAPP establece los términos conforme a los cuales los datos personales y otra información
recopiladas o proporcionada por el Usuario deberá ser tratada.
17. Ley Aplicable
17.1. Los presentes Términos y Condiciones se regirán por las leyes aplicables Colombianas.
17.2. En caso de una disputa relacionada con los Servicios, el Usuario primero buscará la solución de las disputas refiriendo la disputa a GRUAPP a través de GRUAPP. GRUAPP investigará el asunto y realizará sus mejores esfuerzos para resolver las disputas al recibir la notificación. Si las Partes no pueden resolver la disputa de buena fe, dicha disputa se resolverá mediante arbitraje de conformidad con los términos establecidos a continuación.
17.3. Cualquier conflicto, reclamo o controversia que surja de o en conexión con el incumplimiento, terminación, interpretación, o validez de estos Términos y Condiciones o el uso de los sitios web de GRUAPP o GRUAPP, será sometido a, y resuelto de manera definitiva, en un tribunal de arbitramento administrado por el Centro de Arbitraje de la cámara de comercio, reglas que se consideran incorporadas por referencia a esta cláusula. 
18. Subsistencia
Aun cuando los presentes Términos y Condiciones se den por terminados o anulen, las disposiciones relacionadas con la responsabilidad por incumplimiento.17. Ley Aplicable
17.1. Los presentes Términos y Condiciones se regirán por las leyes aplicables Colombianas.
17.2. En caso de una disputa relacionada con los Servicios, el Usuario primero buscará la solución de las disputas refiriendo la disputa a GRUAPP a través de GRUAPP. GRUAPP investigará el asunto y realizará sus mejores esfuerzos para resolver las disputas al recibir la notificación. Si las Partes no pueden resolver la disputa de buena fe, dicha disputa se resolverá mediante arbitraje de conformidad con los términos establecidos a continuación.
17.3. Cualquier conflicto, reclamo o controversia que surja de o en conexión con el incumplimiento, terminación, interpretación, o validez de estos Términos y Condiciones o el uso de los sitios web de GRUAPP o GRUAPP, será sometido a, y resuelto de manera definitiva, en un tribunal de arbitramento administrado por el Centro de Arbitraje de la cámara de comercio, reglas que se consideran incorporadas por referencia a esta cláusula. 
18. Subsistencia
Aun cuando los presentes Términos y Condiciones se den por terminados o anulen, las disposiciones relacionadas con la responsabilidad por incumplimiento.


        </div> 
        </div>
        
    </div>
}

export default TyC;